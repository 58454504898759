import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import { IntlProvider } from 'react-intl'
import { useDispatch } from 'react-redux'
import { ColumnActionsMode, Stack, Link, PrimaryButton, MessageBarType } from '@fluentui/react'
import { Card, Section } from '@/shared/components'
import { Table } from '@/shared/components'
import { IModifiedColumn } from '@/shared/components/Table/types'
import DocuSignEmbeddedSigningView, { DocuSignEmbeddedSigningViewCloseEvent } from '@/shared/components/DocuSign/DocuSignEmbeddedSigningView/DocuSignEmbeddedSigningView'
import { useGetDocuSignEmployeeSummaryQuery, useUpdateStatusMutation } from '@/shared/api/services/docuSignService'
import { createUIMessage } from '@/features/uimessages/redux/operations'
import Confirmation from '@/shared/components/Confirmation'
import SignersCell from '../DocumentSignatureSetup/components/SignerCells/SignerCell'

import './DocumentSignatureReview.scss'

export interface DocumentsPendingSignatureProps {
  accountId: string
  setDocumentCount: (count: number) => void,
  headerLabel: string
}

const UI_MESSAGE_DISMISS_TIMEOUT = 3000

export default function DocumentSignatureReview({ accountId, setDocumentCount, headerLabel }: DocumentsPendingSignatureProps) {
  const dispatch = useDispatch()
  const [signingUrl, setSigningUrl] = useState<string>()
  const [docusignId, setDocuSignId] = useState<string>()
  const { data, isLoading, isError } = useGetDocuSignEmployeeSummaryQuery({ accountId })
  const [updateStatus] = useUpdateStatusMutation();
  const [isDialogShown, setIsDialogShown] = useState(false)
  const [isDocumentConfirmed, setDocumentConfirmed] = useState(false)
  const displaySigningView = !!docusignId && !!signingUrl && !!isDocumentConfirmed

  useEffect(() => {
    if (data) {
      const count = data.filter(item => item.viewURL !== null && item.documentStatus != 'Expired').length
      setDocumentCount(count)
    } else {
      setDocumentCount(0)
    }
  }, [data, setDocumentCount])

  const alertConfirmation = () => {
    updateStatus({ docusignId: docusignId, newStatus: 'Clicked' })
      .then(() => {
        setDocumentConfirmed(true)
        setIsDialogShown(false)
      })
      .catch((error: any) => {
        console.error('Error updating status:', error)
        setDocumentConfirmed(false)
        setIsDialogShown(false)
      });
  }

  const columns: IModifiedColumn[] = [
    {
      flexGrow: 0,
      minWidth: 180,
      name: 'NAME',
      key: 'name',
      fieldName: 'templateName',
      className: 'name name-fixed-column',
      headerClassName: 'name name-fixed-column',
    },
    {
      flexGrow: 0,
      minWidth: 180,
      name: 'CREATED DATE',
      key: 'date',
      fieldName: 'creationDate',
      className: 'date',
      headerClassName: 'date',
      onRender: (data: any) => {
        const { creationDate } = data
        const formattedDate = dayjs(creationDate).format('LL')
        return (
          <time className="c-document-signature-setup__date" dateTime={formattedDate}>
            {formattedDate}
          </time>
        )
      },
    },
    {
      flexGrow: 0,
      minWidth: 180,
      name: 'REQUIRED SIGNERS',
      key: 'signers',
      fieldName: 'signers',
      className: 'signers',
      headerClassName: 'signers',
      onRender: (data: any) => {
        return (<SignersCell signers={data.signers} filter='' />)
      },
    },
    {
      flexGrow: 0,
      minWidth: 180,
      name: 'SIGNED BY',
      key: 'signedBy',
      fieldName: 'signedBy',
      className: 'signedBy',
      headerClassName: 'signedBy',
      onRender: (data: any) => {
        return (<SignersCell signers={data.signers} filter='completed' />)
      },
    },
    {
      flexGrow: 0,
      minWidth: 180,
      name: 'EXPIRED DATE',
      key: 'expiredDate',
      fieldName: 'expiredBy',
      className: 'expiredDate',
      headerClassName: 'expiredDate',
      onRender: (data: any) => {
        const { expiredBy, signers } = data
        const status = signers[0]?.status || 'Pending Signature'
        const formattedDate = dayjs(expiredBy).format('LL')
        const daysDifference = dayjs().diff(dayjs(formattedDate), 'days') - 1
        const absoluteDaysDifference = Math.abs(daysDifference)
        const daysClass = daysDifference > 0 ? 'negative-days' : absoluteDaysDifference < 4 ? 'positive-days-3' : 'focus'
        return (
          <div className={`c-document-signature-setup__expiredDate ${(status === 'Expired') ? 'focus' : ''}`}>
            <time className="formattedDate" dateTime={formattedDate}>{formattedDate}</time>
            <span className={`expiredCount ${daysClass}`} style={{ fontWeight: 'bold' }}>
              &nbsp;({absoluteDaysDifference} days)
            </span>
          </div>
        )
      },
    },
    {
      flexGrow: 0,
      minWidth: 80,
      name: 'STATUS',
      key: 'status',
      fieldName: 'status',
      className: 'status',
      headerClassName: 'status',
      onRender: (data: any) => {
        const { documentStatus } = data
        const status = documentStatus || 'Pending'
        return (
          <div className={`c-document-signature-setup__expiredDate ${(status === 'Expired') ? 'focus' : ''}`}>
            {documentStatus}
          </div>
        )
      },
    },
    {
      flexGrow: 0,
      minWidth: 100,
      name: '',
      key: 'viewURL',
      fieldName: 'viewURL',
      className: 'actions fixed-column',
      headerClassName: 'actions',
      isPadded: true,
      columnActionsMode: ColumnActionsMode.disabled,
      onRender: (data: any) => {
        const { docusignId, documentStatus, viewURL } = data

        if (!viewURL || documentStatus === 'Expired') {
          return ''
        }

        return (
          <Stack horizontal horizontalAlign="end" className="c-clients-table__actions" margin-left='50px'>
            <Link onClick={() => {
              setIsDialogShown(true)
              setDocuSignId(docusignId)
              setSigningUrl(viewURL)
            }}>Sign Document</Link>
          </Stack>
        )
      },
    }
  ]

  const onSigningViewClose = (event: DocuSignEmbeddedSigningViewCloseEvent) => {
    setDocuSignId(undefined)
    setSigningUrl(undefined)
    setDocumentConfirmed(false)
    setIsDialogShown(false)

    if (event && event.status === 'Completed') {
      dispatch(createUIMessage({
        key: 'postSigningViewClose',
        content: 'Document Signing Complete.',
        messageBarType: MessageBarType.success,
        autoDismissAfter: UI_MESSAGE_DISMISS_TIMEOUT,
      }))
    }
  }

  if (isLoading) {
    return <div>Loading...</div>
  }

  if (isError) {
    return <div>Error loading data</div>
  }

  return (
    <IntlProvider locale='en'>
      <Section className='c-document-signature-setup'>
        <Card ariaLabel={headerLabel} heading={headerLabel}>
          {!isDocumentConfirmed ? (
            <Table
              shimmerLineCount={5}
              shimmerIsEnabled={isLoading}
              compact={true}
              columns={columns}
              data={data || []} // Use fetched data
            />
          ) : (
            <div>
              <DocuSignEmbeddedSigningView
                signingUrl={signingUrl}
                docusignId={docusignId}
                onClose={onSigningViewClose}
              />
              <PrimaryButton onClick={() => {
                setDocuSignId(undefined)
                setSigningUrl(undefined)
                setDocumentConfirmed(false)
                setIsDialogShown(false)
              }}>
                Back
              </PrimaryButton>
            </div>
          )}
        </Card>
        <Confirmation
          hidden={!isDialogShown}
          title="Signing Document"
          subText="Are you sure you want to sign this document?"
          handleDismiss={() => {
            setDocuSignId(undefined)
            setSigningUrl(undefined)
            setDocumentConfirmed(false)
            setIsDialogShown(false)
          }}
          handleNo={() => {
            setDocuSignId(undefined)
            setSigningUrl(undefined)
            setDocumentConfirmed(false)
            setIsDialogShown(false)
          }}
          handleYes={alertConfirmation}
        />
      </Section>
    </IntlProvider>
  )
}
