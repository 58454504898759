import dayjs from 'dayjs'
import { useEffect } from 'react'
import { Link as RRLink } from 'react-router-dom'
import { ColumnActionsMode, Link, Stack } from '@fluentui/react'
import { Section, Table } from '@/shared/components'
import { IModifiedColumn } from '@/shared/components/Table/types'
import { useGetDocuSignSummaryQuery } from '@/shared/api/services/docuSignService'
import SkeletonDashboardDocuments from '../DashboardDocumentWidget/SkeletonDashboardDocuments'

import './PendingDocumentsSignature.scss'

export interface PendingDocumentsSignatureProps {
  accountId: string,
  setDocumentCount: (count: number) => void,
}

export default function PendingDocumentsSignature({ accountId, setDocumentCount }: PendingDocumentsSignatureProps) {
  const headerLabel = 'Documents Pending Signature'
  const isUninitialized = false
  const { data, isLoading, isFetching, isError } = useGetDocuSignSummaryQuery({ accountId })

  useEffect(() => {
    if (data) {
      const count = data.filter(item => item.viewURL !== null && item.documentStatus !='Expired' ).length;
      setDocumentCount(count);
    } else {
      setDocumentCount(0);
    }
  }, [data, setDocumentCount]);

  const columns: IModifiedColumn[] = [
    {
      flexGrow: 0,
      minWidth: 180,
      name: 'NAME',
      key: 'name',
      fieldName: 'templateName',
      className: 'name name-fixed-column',
      headerClassName: 'name name-fixed-column',
    },
    {
      flexGrow: 0,
      minWidth: 180,
      name: 'CREATED DATE',
      key: 'date',
      fieldName: 'creationDate',
      className: 'date',
      headerClassName: 'date',
      onRender: (data: any) => {
        const { creationDate } = data
        const formattedDate = dayjs(creationDate).format('LL')
        return (
          <time className="c-document-signature-setup__date" dateTime={formattedDate}>
            {formattedDate}
          </time>
        )
      },
    },
    {
      flexGrow: 0,
      minWidth: 180,
      name: '',
      key: 'status',
      fieldName: 'status',
      className: 'status',
      headerClassName: 'status',
      isPadded: true,
      columnActionsMode: ColumnActionsMode.disabled,
      onRender: (data: any) => {
        const { accountId, envelopeId } = data
        return (
          <Stack horizontal horizontalAlign="end" className="c-clients-table__actions" margin-left='50px'>
            <Link as={RRLink} to={`/documents?tab=pendingSignature&envelopeId=${envelopeId}`}>Sign Document</Link>
          </Stack>
        )
      },
    }
  ]

  return (
    <>
      {
        !isFetching ? (
          <Section className='c-pending-documents-signature' title={headerLabel}>
            <Table
              shimmerLineCount={5}
              shimmerIsEnabled={isLoading || isUninitialized}
              compact={true}
              columns={columns}
              data={data || []}
            />
          </Section>) : (<SkeletonDashboardDocuments></SkeletonDashboardDocuments>)
      }
    </>
  )
}
