import React, { useEffect, useRef, useState } from 'react'
import { SigningCompleteMessageEvent, SigningCompleteMessageEventType } from '@/screens/DocuSign/SiginingComplete'
import { DocuSignAccountInfo, DocuSignEnvelope, useGetAccountInfoQuery, usePostSyncDocuSignEnvelopeMutation } from '@/shared/api/services/docuSignService'

import './DocuSignEmbeddedConsoleView.scss'

declare global {
  interface Window {
    DocuSign: any
  }
}

export interface DocuSignEmbeddedConsoleViewCloseEvent {
  eventSource: string,
  envelope?: DocuSignEnvelope,
  isSyncError?: boolean,
}

export interface DocuSignEmbeddedConsoleViewProps {
  accountId: string,
  senderViewUrl: string,
  envelopeId: string,
  onClose?: (event: DocuSignEmbeddedConsoleViewCloseEvent) => void,
}

const DocuSignEmbeddedConsoleView: React.FC<DocuSignEmbeddedConsoleViewProps> = ({ accountId, senderViewUrl, envelopeId, onClose }) => {
  const [ syncDocuSignEnvelope ] =  usePostSyncDocuSignEnvelopeMutation()
  const { data: docuSignAccountInfo } = useGetAccountInfoQuery()
  const { clientId } = docuSignAccountInfo || {} as DocuSignAccountInfo
  const [ isCloseLoaderEnabled, setCloseLoaderEnabled ] = useState(false)
  const [ isConsoleViewLoaded, setConsoleViewLoaded ] = useState(false)
  const consoleViewLoadedRef = useRef(false)
  const containerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (!envelopeId) {
      return
    }

    function messageEventListener(event: MessageEvent<SigningCompleteMessageEvent>) {
      if (event) {
        const { data } = event
        if (data && data.type === SigningCompleteMessageEventType) {
          const { detail } = data
          
          if (detail && detail.envelopeId === envelopeId) {
            setCloseLoaderEnabled(true)
            syncDocuSignEnvelope({
              envelopeId,
              accountId,
            }).unwrap().then(docuSignEnvelope => {
              setCloseLoaderEnabled(false)
              if (onClose) {
                onClose({
                  envelope: docuSignEnvelope,
                  eventSource: detail.event,
                })
                setTimeout(() => {
                  window.removeEventListener('message', messageEventListener)
                }, 500)
              }
            }).catch(error => {
              console.error('Failed to sync DocuSign envelope.', error)
              if (onClose) {
                onClose({
                  eventSource: detail.event,
                  isSyncError: true,
                })
                setTimeout(() => {
                  window.removeEventListener('message', messageEventListener)
                }, 500)
              }
            })
          }
        }
      }
    }

    window.addEventListener('message', messageEventListener)
  }, [envelopeId])


  useEffect(() => {
    if (!senderViewUrl || !clientId || consoleViewLoadedRef.current) {
      return
    }

    console.log('Initializing console view', clientId)

    if (window.DocuSign) {
      window.DocuSign.loadDocuSign(clientId)
        .then((docusign: any) => {
          const consoleViewWindow = docusign.webforms({
            url: senderViewUrl,
            options: {
              autoResizeHeight: true,
              displayFormat: 'focused',
              style: {
                branding: {
                  primaryButton: {
                    backgroundColor: '#333',
                    color: '#fff',
                  },
                },
                signingNavigationButton: {
                  finishText: 'Complete Signing',
                  position: 'bottom-left',
                },
              },
            },
          })

          // mark console view as loaded
          consoleViewLoadedRef.current = true
          setConsoleViewLoaded(true)

          // init console view on div container
          consoleViewWindow.mount(containerRef.current)
        })
        .catch((error: any) => {
          console.error('Error loading DocuSign:', error)
        })
    }
  }, [clientId, senderViewUrl, consoleViewLoadedRef, isConsoleViewLoaded, setConsoleViewLoaded])

  return (
    <div
      className="DocuSignEmbeddedConsoleView"
      ref={containerRef}
      style={{ width: '100%', height: '100vh', overflow: 'hidden' }}
    />
  )
}

export default DocuSignEmbeddedConsoleView