import { DOCUSIGN_REDIRECT_URL } from '@/environment/environment.constants'
import { useEffect, useRef, useState } from 'react'
import { IntlProvider } from 'react-intl'
import { useDispatch } from 'react-redux'
import { ChoiceGroup, DefaultButton, MessageBarType, PrimaryButton } from '@fluentui/react'
import { Card, Section } from '@/shared/components'
import DocuSignTemplateSelector from './components/DocuSignTemplateSelector/DocuSignTemplateSelector'
import { TeamSelectorModel } from '@/shared/components/TeamSelector/TeamSelector'
import { DocuSignSenderViewResponse, DocuSignTemplate, SignerDetails, usePostEnvelopeViewUrlMutation, usePostSenderViewUrlMutation } from '@/shared/api/services/docuSignService'
import { createUIMessage } from '@/features/uimessages/redux/operations'
import DocuSignEmbeddedConsoleView, { DocuSignEmbeddedConsoleViewCloseEvent } from '@/shared/components/DocuSign/DocuSignEmbeddedConsoleView/DocuSignEmbeddedConsoleView'
import ClientUserSelector from './components/ClientUserSelector/ClientUserSelector'
import CAUserSelector from './components/CAUserSelector/CAUserSelector'

import './DocumentSignatureSetup.scss'

export interface DocumentSignatureSetupProps {
  accountId: string,
}

const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window
  return {
    width,
    height
  }
}

const UI_MESSAGE_DISMISS_TIMEOUT = 3000
const MODAL_PARAM_NAME = 'docuSignModel'

export default function DocumentSignatureSetup({ accountId }: DocumentSignatureSetupProps) {
  const headerLabel = 'Documents Signature Setup'
  const [selectedKey, setSelectedKey] = useState('docusignTemplate')
  const [selectedTemplate, setSelectedTemplate] = useState(null as DocuSignTemplate)
  const [selectedClientUsers, setSelectedClientUsers] = useState([] as TeamSelectorModel[])
  const [selectedCAUsers, setSelectedCAUsers] = useState([] as TeamSelectorModel[])
  const [postEnvelopeViewUrl] = usePostEnvelopeViewUrlMutation()
  const [postSenderView] = usePostSenderViewUrlMutation()
  const dispatch = useDispatch()
  const clientUserSelectorRef = useRef<{ resetItems: () => void }>(null)
  const caUserSelectorRef = useRef<{ resetItems: () => void }>(null)
  const [showSignatureSetup, setShowSignatureSetup] = useState(false)
  const [senderViewResponse, setSenderViewResponse] = useState<DocuSignSenderViewResponse>()
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions())

  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions(getWindowDimensions())
    }
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])


  function generateSignerDetails(user: TeamSelectorModel): SignerDetails {
    return {
      signer: {
        email: user.email,
        name: user.fullName
      },
      caId: user.caId,
      contactId: user.contactId,
      contactKey: user.contactKey,
      accountId: accountId
    }
  }

  function prepareEnvelopeData(selectedTemplate: DocuSignTemplate, selectedClientUsers: TeamSelectorModel[], selectedCAUsers: TeamSelectorModel[]) {
    const templateId = selectedTemplate?.templateId
    const signers = selectedClientUsers.map(user => generateSignerDetails(user))
    const caUserSigner = selectedCAUsers.map(user => generateSignerDetails(user))
    const allSigners = signers.concat(caUserSigner)
    return { templateId, allSigners }
  }

  const resetState = () => {
    // Clear the states after successful envelope send
    setSelectedTemplate(null)
    setSelectedClientUsers([])
    setSelectedCAUsers([])
    setSelectedKey('docusignTemplate')
    if (clientUserSelectorRef.current && caUserSelectorRef.current) {
      clientUserSelectorRef.current.resetItems() // Reset client users
      caUserSelectorRef.current.resetItems() // Reset CA users
    }
  }
  const onSubmitDocumentClick = async () => {
    if (!selectedTemplate) {
      console.error('No template selected')
      return
    }

    const { templateId, allSigners } = prepareEnvelopeData(selectedTemplate, selectedClientUsers, selectedCAUsers)

    postEnvelopeViewUrl({
      templateId: templateId,
      envelopeRequest: {
        signers: allSigners,
        recipients: [],
      },
      returnUrl: DOCUSIGN_REDIRECT_URL, // or any other return URL you want to use
    }).unwrap().then(() => {
      dispatch(createUIMessage({
        key: 'postEnvelopeSuccess',
        content: 'Envelope sent successfully.',
        messageBarType: MessageBarType.success,
        autoDismissAfter: UI_MESSAGE_DISMISS_TIMEOUT,
      }))
      resetState()
    }).catch(error => {
      dispatch(createUIMessage({
        key: 'postEnvelopeFailure',
        content: 'Failed to send envelope.',
        messageBarType: MessageBarType.error,
        autoDismissAfter: UI_MESSAGE_DISMISS_TIMEOUT,
      }))
    })
  }

  const onSetupSignaturePlaceHoldersClick = async () => {

    const { templateId, allSigners } = prepareEnvelopeData(selectedTemplate, selectedClientUsers, selectedCAUsers)

    try {
      const response = await postSenderView({
        templateId: templateId,
        envelopeRequest: {
          signers: allSigners,
          recipients: [],
        },
        returnUrl: DOCUSIGN_REDIRECT_URL,
      }).unwrap()

      setSenderViewResponse(response)
      setShowSignatureSetup(true)
      resetState()
    } catch (error) {
      dispatch(createUIMessage({
        key: 'postEnvelopeFailure',
        content: 'Failed to create envelope.',
        messageBarType: MessageBarType.error,
        autoDismissAfter: UI_MESSAGE_DISMISS_TIMEOUT,
      }))
    }
  }

  const validateTemplateState = () => {
    return selectedKey !== 'docusignTemplate' || !selectedTemplate || (!selectedClientUsers.length && !selectedCAUsers.length)
  }

  const validateCustumUploadState = () => {
    return selectedKey !== 'docusignFileUpload' || (!selectedClientUsers.length && !selectedCAUsers.length)
  }

  const onConsoleViewClose = (event: DocuSignEmbeddedConsoleViewCloseEvent) => {
    setSenderViewResponse(undefined)
    setShowSignatureSetup(false)

    if (event.eventSource === 'Send') {
      dispatch(createUIMessage({
        key: 'postConsoleViewClose',
        content: 'Envelope sent successfully.',
        messageBarType: MessageBarType.success,
        autoDismissAfter: UI_MESSAGE_DISMISS_TIMEOUT,
      }))
    }
  }

  const getValue = () => {
    const { width } = windowDimensions;

    if (width > 1024) {
      return (width / 2) - 200;
    } else if (width > 645) {
      return width - 250;
    } else {
      return 400;
    }
  }

  return (
    <IntlProvider locale='en'>
      <Section className='c-document-signature-setup'>
        {!showSignatureSetup ? (
          <Card ariaLabel={headerLabel} heading={headerLabel} className='c-document-signature-setup-card'>
            <div className='user-selection-container'>
              <div className='selection-container'>
                <div className='title'>Client Users *</div>
                <div className='component'>
                  <ClientUserSelector teamSelectorWidth={getValue()} ref={clientUserSelectorRef} accountId={accountId} onSelectedItems={setSelectedClientUsers} />
                </div>
              </div>
              <div className='selection-container'>
                <div className='title'>CA Users</div>
                <div className='component'>
                  <CAUserSelector teamSelectorWidth={getValue()} ref={caUserSelectorRef} accountId={accountId} onSelectedItems={setSelectedCAUsers} />
                </div>
              </div>
            </div>

            <hr className='separator' />

            <div className='docusign-workflow-container'>
              <ChoiceGroup
                className='c-create-event-modal-form__choicegroup'
                selectedKey={selectedKey}
                onChange={(ev, option) => setSelectedKey(option.key)}
                options={[
                  {
                    key: 'docusignTemplate',
                    text: 'Select a Template',
                    onRenderField: (props, render) => {
                      return (
                        <>
                          {render!(props)}
                          <div className='docusign-template-container'>
                            <div className='title'>DocuSign Template</div>
                            <div className='component'>
                              <DocuSignTemplateSelector
                                disabled={selectedKey !== 'docusignTemplate'}
                                selectedOption={selectedTemplate}
                                onValueChange={(docusignTemplate) => setSelectedTemplate(docusignTemplate)} />
                            </div>
                          </div>
                        </>
                      )
                    }
                  },
                  {
                    key: 'docusignFileUpload',
                    text: 'Custom Upload',
                    onRenderField: (props, render) => {
                      return (
                        <>
                          {render!(props)}
                        </>
                      )
                    }
                  }
                ]}
              />
            </div>
            <div className='action-buttons'>
              <PrimaryButton disabled={validateTemplateState()} className='submit-document-button' onClick={onSubmitDocumentClick}>Submit Document</PrimaryButton>
              <DefaultButton disabled={validateCustumUploadState()} className='setup-signature-placeholders-button' onClick={onSetupSignaturePlaceHoldersClick}>Setup Signature Placeholders</DefaultButton>
            </div>
          </Card>
        ) : (
          <div>
            { senderViewResponse ? (
              <DocuSignEmbeddedConsoleView accountId={accountId} senderViewUrl={senderViewResponse.url} envelopeId={senderViewResponse.envelopeId} onClose={onConsoleViewClose} />
            ) : null }
            <PrimaryButton onClick={() => {
              setShowSignatureSetup(false)
              setSenderViewResponse(undefined)
            }}>
              Back
            </PrimaryButton>
          </div>
        )}
      </Section>
    </IntlProvider>
  )
}