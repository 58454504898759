import { Card, Section } from '@/shared/components'
import useQueryParamSubscription from '@/shared/hooks/useQueryParamSubscription'
import { useEffect } from 'react'
import Skeleton from 'react-loading-skeleton'

import './SiginingComplete.scss'

export const SigningCompleteMessageEventType = 'SigningCompleteMessageEvent'

export interface SigningCompleteMessageEvent {
  type: string,
  detail: {
    envelopeId: string,
    event: string,
  }
}

export default function SigningComplete() {
  const [envelopeId] = useQueryParamSubscription<string>('envelopeId')
  const [event] = useQueryParamSubscription<string>('event')

  useEffect(() => {
    if (!envelopeId || !event) {
      return
    }

    const message: SigningCompleteMessageEvent = {
      type: 'SigningCompleteMessageEvent',
      detail: {
        envelopeId,
        event,
      }
    }

    if (window && window.parent) {
        // Send the message to the parent window
        window.parent.postMessage(message, '*')
    }
  }, [envelopeId, event])

  return (
    <Section className='c-document-signature-loading'>
      <Card ariaLabel="Loading" heading="Loading...">
        <div className='c-skeleton' role="presentation">
          <div className='client-skeleton__left'>
            <div className='c-skeleton__left__title'>
              <Skeleton width="100%" count={2} />
            </div>
            <Skeleton width={80} />
            <div className='c-skeleton__left__description'>
              <Skeleton width="100%" count={3} />
            </div>
          </div>
        </div>
      </Card>
    </Section>
  )
}
