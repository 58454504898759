import React from 'react'
import StyledBadge from '@mui/material/Badge'
import './BadgeWithText.scss'

interface BadgeWithTextProps {
  text: string;
  badgeContent: number;
  color?: 'default' | 'primary' | 'secondary' | 'error';
}

const BadgeWithText: React.FC<BadgeWithTextProps> = ({ text, badgeContent, color = 'primary' }) => {
  return (
    <>
      <span>
        {text}
        <StyledBadge className='c-documents-badge' badgeContent={badgeContent} color={color}>
        </StyledBadge>
      </span>
    </>
  );
};

export default BadgeWithText;