
import { forwardRef, useImperativeHandle, useState } from 'react'
import TeamSelector, { TeamSelectorMemberProps, TeamSelectorModel } from '@/shared/components/TeamSelector/TeamSelector'
import { useSearchTeamMemberMutation } from '@/shared/api/services/teamService'

import './CAUserSelector.scss'
import { IBasePickerStyles } from '@fluentui/react'

export interface CAUserSelectorProps {
  accountId: string,
  onSelectedItems: (selectedItems: TeamSelectorModel[]) => void,
  teamSelectorWidth?: number
}

const CAUserSelector = forwardRef(({ accountId, onSelectedItems, teamSelectorWidth }: CAUserSelectorProps, ref) => {
  const [ selectedItems, setSelectedItems ] = useState([] as TeamSelectorMemberProps[])

  const [getTeamMembers] = useSearchTeamMemberMutation()
  
  useImperativeHandle(ref, () => ({
    resetItems: () => setSelectedItems([]),
  }));
  
  const comboBoxStyles: Partial<IBasePickerStyles> = {
    root: {
      width: teamSelectorWidth
    }
  }

  const calloutProps = {
    calloutWidth: teamSelectorWidth,
    target: `.team-selector-CAUser`,
  }

  const caUserServiceFilter = async (filter: string) => {
    const data = await getTeamMembers(filter).unwrap()
    return [
      ...data.map(c => ({
        ...c,
        id: c.peopleId.toString(),
        peopleId: c.peopleId,
      }))
    ]
  }

  const onSelect = (selectedOptions: TeamSelectorMemberProps[]) => {
    setSelectedItems(selectedOptions)
    onSelectedItems(selectedOptions.map(v => v.sourceMember))
  }

  return (
    <TeamSelector classNameCustom='CAUser' comboStyle={comboBoxStyles} calloutProps={calloutProps} selectedItems={selectedItems} onFilter={caUserServiceFilter} onSelect={onSelect} />
  )

})


CAUserSelector.displayName = 'CAUserSelector';
export default CAUserSelector
