import { ComponentType } from 'react'
import * as Screens from '@/screens'
import { RouteComponentProps } from 'react-router'
import { EUserCan, Permission } from '@/features/auth/types'
import { IS_SANDBOX_ENVIRONMENT } from '@/environment/environment.constants';
import SigningComplete from '@/screens/DocuSign/SiginingComplete';

export interface RouteConfigItem {
  path: string;
  labelKey: string;
  exact: boolean;
  screen: ComponentType<RouteComponentProps>;
  authority?: Permission;
  /**
   * Use named capture groups for passing through state to MSAL redirect target
   * Reserved words for capture groups: `pathKey`, `query`
   */
  pattern?: RegExp;
  /**
   * Used for persisting the query when redirecting through MSAL
   * @default false
   */
  preserveQuery?: boolean;
}

const includeDevScreens = IS_SANDBOX_ENVIRONMENT

export type RouteKey = 'signingComplete' | 'readOnlyOnboardingDashboard' | 'onboardingDashboard' | 'home' | 'clients' | 'client' | 'clientSupport'| 'portfolio' | 'dashboard' | 'previewDashboard' | 'previewPortfolioDashboard' | 'documents' | 'document' | 'sharedDocumentLink' | 'support' | 'insights' | 'more' | 'moreChild'| 'profile' | 'events' | 'notifications' | 'messages' | 'surveyLinks'

export const routes: Record<RouteKey, RouteConfigItem> = {
  'signingComplete': {
    path: '/signing-complete',
    labelKey: 'common:dashboard',
    exact: true,
    screen: SigningComplete,
  },
  home: {
    path: '/',
    labelKey: 'common:dashboard',
    exact: true,
    screen: Screens.Home,
    authority: EUserCan.Access_Dashboard,
  },
  dashboard: {
    path: '/dashboard',
    labelKey: 'common:dashboard',
    exact: true,
    screen: Screens.Home,
    authority: EUserCan.Access_Dashboard,
  },
  portfolio: {
    path: '/portfolio/:portfolioId/:asOfDate/:sleeveId?',
    labelKey: 'common:portfolio',
    exact: true,
    screen: Screens.ClientPortfolio,
  },
  previewDashboard: {
    path: '/preview/dashboard/:accountId',
    labelKey: 'common:dashboard',
    exact: true,
    screen: Screens.PreviewDashboard,
    authority: EUserCan.Preview_Dashboard,
  },
  previewPortfolioDashboard: {
    path: '/preview/dashboard/:accountId/portfolio/:portfolioId/:asOfDate/:sleeveId?',
    labelKey: 'common:portfolio',
    exact: true,
    screen: Screens.PreviewClientPortfolio,
    authority: EUserCan.Preview_Dashboard,
  },
  readOnlyOnboardingDashboard: {
    path: '/dashboard/onboarding',
    labelKey: 'common:dashboard',
    exact: true,
    screen: Screens.ClientOnboardingDashboard,
    authority: EUserCan.Access_Dashboard,
  },
  onboardingDashboard: {
    path: '/onboarding/:accountId',
    labelKey: 'common:onboardingLabel',
    exact: true,
    screen: Screens.EditableOnboardingDashboard,
    authority: EUserCan.Access_OnboardingDashboard,
  },
  clients: {
    path: '/clients',
    labelKey: 'common:clientsLabel',
    exact: true,
    screen: Screens.Clients,
    authority: EUserCan.Access_Clients,
  },
  client: {
    path: '/clients/:accountid',
    labelKey: 'common:clientLabel',
    exact: true,
    screen: Screens.Client,
    authority: EUserCan.Access_Client,
  },
  clientSupport: {
    path: '/clients/:accountid/support',
    labelKey: 'common:supportLabel',
    exact: true,
    screen: Screens.ClientSupport,
    authority: EUserCan.Access_Client,
  },
  documents: {
    path: '/documents',
    labelKey: 'common:documentsLabel',
    exact: true,
    screen: Screens.Documents,
    preserveQuery: true,
  },
  document: {
    path: '/documents/:fileId',
    labelKey: 'common:documentLabel',
    exact: true,
    screen: Screens.Document,
    pattern: /\/documents\/(?<fileId>\d+)\/?\??/gm
  },
  sharedDocumentLink: {
    path: '/sharedDocumentLink/:accountId',
    labelKey: 'common:documentLabel',
    exact: true,
    screen: Screens.SharedDocumentLink,
  },
  messages: {
    path: '/messages',
    labelKey: 'messages:messages',
    exact: true,
    screen: Screens.Messages
  },
  support: {
    path: '/support',
    labelKey: 'common:supportLabel',
    exact: false,
    screen: Screens.Support
  },
  insights: {
    path: '/insights',
    labelKey: 'nav:insights',
    exact: false,
    screen: Screens.Insights,
    authority: EUserCan.Access_Insights
  },
  more: {
    path: '/more',
    labelKey: 'nav:more',
    exact: true,
    screen: Screens.More,
    authority: EUserCan.Access_More_Links,
  },
  moreChild: {
    path: '/more/:application',
    labelKey: '',
    exact: true,
    screen: Screens.MoreChild,
  },
  profile: {
    path: '/profile', // this will probably eventually be something to deal with the user role - user/profile?
    labelKey: 'common:profile',
    exact: true,
    screen: Screens.Profile,
  },
  events: {
    path: '/events',
    labelKey: 'nav:events',
    exact: true,
    screen: Screens.Events,
    authority: EUserCan.Access_Events
  },
  notifications: {
    path: '/notifications',
    labelKey: 'common:notificationLabel',
    exact: true,
    screen: Screens.Notifications
  },
  surveyLinks: {
    path: '/surveyLinks',
    labelKey: 'nav:surveyLinks',
    exact: true,
    screen: Screens.SurveyLinks,
    authority: EUserCan.Access_SurveyLinks,
  },
  ...(includeDevScreens ? {
    components: {
      path: '/components',
      labelKey: 'common:componentsLabel',
      exact: false,
      screen: Screens.ComponentsGuide
    }
  } : {})
}