import { useLayoutEffect, useRef } from 'react';
import { ComboBox, IComboBoxOption, IComboBoxStyles } from '@fluentui/react'
import { DocuSignTemplate, useGetTemplatesQuery } from '@/shared/api/services/docuSignService';

import './DocuSignTemplateSelector.scss'

export interface DocuSignTemplateSelectorProps {
  disabled: boolean,
  selectedOption?: DocuSignTemplate,
  onValueChange: (selectedOption: DocuSignTemplate) => void,
}

const DocuSignTemplateSelector = ({ disabled = false, selectedOption, onValueChange }: DocuSignTemplateSelectorProps) => {
  const dropdownRef = useRef(null)
  const { data } = useGetTemplatesQuery()
  const selectedKey = selectedOption && selectedOption.templateId

  useLayoutEffect(() => {
    if (dropdownRef) {
      const container = dropdownRef.current as HTMLElement
      
      if (container) {
        const baseContainer = container.querySelector('.ms-ComboBox') as HTMLElement
        
        if (baseContainer) {
          const input = container.querySelector('input') as HTMLElement
          
          if (input) {
            input.setAttribute('disabled', 'disabled')
          }
        }
      }
    }
  },[selectedOption])

  const boxFolderPermisionDropdownStyles: Partial<IComboBoxStyles> = { 
    root: {
      width: 380,
      outline: 'none',
      lineHeight: 'normal',
    },
    optionsContainerWrapper: {
      maxHeight: 216,
      width: 380
    },
  };
  const boxFolderPermisionDropdownOptions = (data || []).map(t => ({
    key: t.templateId,
    text: t.description,
    templateId:t.templateId,
  }))

  const onBoxFolderPermisionDropdownChange = (option?: IComboBoxOption) => {
    onValueChange(option as any)
  }

  return (
    <ComboBox
      ref={dropdownRef}
      disabled={disabled}
      className={`DocuSignTemplateSelector ${selectedOption ?  'has-value' : ''}`}
      selectedKey={selectedKey}
      options={boxFolderPermisionDropdownOptions}
      styles={boxFolderPermisionDropdownStyles}
      autoComplete='off'
      allowFreeform={false}
      useComboBoxAsMenuWidth={true}
      placeholder='DocuSign Template'
      onChange={(event, option) => onBoxFolderPermisionDropdownChange(option)}
    />
  )
}

export default DocuSignTemplateSelector
