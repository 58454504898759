
import React, { useEffect, useRef, useState } from 'react'
import { DocuSignAccountInfo, useGetAccountInfoQuery, useUpdateStatusMutation } from '@/shared/api/services/docuSignService'

import './DocuSignEmbeddedSigningView.scss'

declare global {
  interface Window {
    DocuSign: any
  }
}

export interface DocuSignEmbeddedSigningViewCloseEvent {
  docusignId: string,
  status: string,
}

export interface DocuSignEmbeddedSigningViewProps {
  signingUrl: string,
  docusignId: string,
  onClose?: (event: DocuSignEmbeddedSigningViewCloseEvent) => void,
}

const DocuSignEmbeddedSigningView: React.FC<DocuSignEmbeddedSigningViewProps> = ({ signingUrl, docusignId, onClose }) => {
  const { data: docuSignAccountInfo } = useGetAccountInfoQuery()
  const { clientId } = docuSignAccountInfo || {} as DocuSignAccountInfo
  const [ isSigningViewLoaded, setSigningViewLoaded ] = useState(false)
  const signingViewLoadedRef = useRef(false)
  const [updateStatus] = useUpdateStatusMutation()
  const containerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (!signingUrl || !clientId || signingViewLoadedRef.current) {
      return
    }

    if (window.DocuSign) {
      window.DocuSign.loadDocuSign(clientId)
        .then((docusign: any) => {
          const signing = docusign.signing({
            url: signingUrl,
            displayFormat: 'focused',
            style: {
              branding: {
                primaryButton: {
                  backgroundColor: '#333',
                  color: '#fff',
                },
              },
              signingNavigationButton: {
                finishText: 'Complete Signing',
                position: 'bottom-left',
              },
            },
          })

          // mark signing view as loaded
          signingViewLoadedRef.current = true
          setSigningViewLoaded(true)

          signing.on('ready', () => console.log('DocuSign UI is ready'))
          signing.on('sessionEnd', (event: any) => {
            console.log('Session ended', event)
            let status = ''

            switch (event.sessionEndType) {
              case 'signing_complete':
                console.log('Signing complete')
                status = 'Completed'
                break
              case 'cancel':
                console.log('Signing cancelled')
                status = 'Cancelled'
                break
              case 'decline':
                console.log('Signing declined')
                status = 'Declined'
                break
              case 'exception':
                console.error('Exception occurred')
                status = 'Exception'
                break
              case 'fax_pending':
                console.log('Fax pending')
                status = 'Fax_pending'
                break
              case 'session_timeout':
                console.log('Session timeout')
                status = 'Timeout'
                break
              case 'ttl_expired':
                console.log('TTL expired')
                status = 'Expired'
                break
              case 'Viewing_complete':
                console.log('Viewing complete')
                status = 'Viewed'
                break
              default:
                console.log('Unknown session end reason')
                status = 'Unknown'
            }

            // Call the updateStatus mutation with the determined status
            updateStatus({
              docusignId,
              newStatus: status,
            })
              .then(() => {
                console.log('Status updated successfully')
                if (onClose) {
                  onClose({
                    docusignId,
                    status,
                  })
                }
              })
              .catch((error: any) => console.error('Error updating status:', error))
          })

          // init signing view on div container
          signing.mount(containerRef.current)
        })
        .catch((error: any) => {
          console.error('Error loading DocuSign:', error)
        })
    }
  }, [signingUrl, docuSignAccountInfo, updateStatus, signingViewLoadedRef, isSigningViewLoaded, setSigningViewLoaded])

  return (
    <div
      className="DocuSignEmbeddedSigningView"
      ref={containerRef}
    />
  )
}

export default DocuSignEmbeddedSigningView